import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';

const Wine = [
    {
        name: 'Tapas',
        price: '100.000 / 150.000',
        description: 'Tapas: 3 - 5 pcs',
    },
    {
        name: 'Charcuterie board',
        price: '250.000 / 300.000',
    },
    {
        name: 'Pickles Mix',
        price: '60.000',
        description: 'Olive - Pickles - Tomato',
    },
    {
        name: 'Chips',
        price: '60.000',
    },
]


function Special() {
    const options = {
        items: 4,
        rewind: true,
        loop: true,
        margin: 45,
        nav: true,
        smartSpeed: 700,
        autoplay: true,
        autoplayTimeout: 7000,
        navText: ['<span class="icon fa-light fa-angle-left"></span>', '<span class="icon fa-light fa-angle-right"></span>'],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            768: {
                items: 2,
                margin: 30
            },
            992: {
                items: 3,
                margin: 30
            },
            1200: {
                items: 4
            }
        }
    };

    return (
        <>
            <section id="menu-section" className="special-offer" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <div className="outer-container" style={{ paddingBottom: 0 }}>
                    <div className="auto-container plausible-event-name=Signatures-click">
                        <div className="title-box centered">
                            <div className="pattern-image">
                                <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Signature, Cocktail, Mocktails' />
                            </div>
                            <h1> Foods </h1>
                        </div>
                    </div>

                    <div className="tabs-box menu-tabs" style={{ marginTop: 20 }}>
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {Wine.map((item, index) => {
                                                if (index % 2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" style={{ paddingLeft: 15, marginBottom: 20 }}>
                                                        <div className="inner-box" style={{ paddingLeft: 0 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{ color: 'rgb(228,197,144)' }}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#"><i>{item.base}</i></Link></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {Wine.map((item, index) => {
                                                if (index % 2 === 0) return null;
                                                return (
                                                    <div className="dish-block" style={{ paddingLeft: 15, marginBottom: 20 }}>
                                                        <div className="inner-box" style={{ paddingLeft: 0 }}>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <div className="ttl clearfix">
                                                                    <h5 style={{ backgroundColor: '#0e0d0c', color: 'white' }}>{item.name}</h5>
                                                                </div>
                                                                <div className="price"><span style={{ color: 'rgb(228,197,144)' }}>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#"><i>{item.base}</i></Link></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>

                    {/* <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}
                    <div style={{ marginTop: 30 }}></div>

                    {/* <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}

                    <div className="auto-container">


                    </div>

                </>
            </section>


        </>

    )
}

export default Special